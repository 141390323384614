<template>
  <div class="ma-0 pa-0 hb-aviary-main-page">
    <v-app-bar
      app
      clipped-left
      color="secondary">
        <hb-btn
          class="ml-n2"
          style="margin-top: -3px;"
          icon
          color="#ffffff"
          active-state-off
          hover-background-color="#004247"
          :mdi-icon="showNavigation ? 'mdi-custom-main-menu' : 'mdi-custom-main-menu-arrow'"
          :tooltip="showNavigation ? 'Hide Navigation' : 'Show Navigation'"
          @click="toggleSidebar"
        />
        <span class="hb-font-header-2-medium white--text pl-4 mt-n1">{{ $vuetify.breakpoint.mdAndUp ? 'Hummingbird Aviary Design System' : 'Aviary' }}</span>
        <v-spacer />

        <HbAutocomplete
          search
          v-model="search"
          :items="menuItems"
          item-text="title"
          return-object
          placeholder="Search"
          @change="goToPage"
        />

        <img v-show="$vuetify.breakpoint.smAndUp" class="mt-n1 ml-6 mr-sm-1 hb-aviary-ti-logo" src="/img/tenantinc-logo-white.png" />
    </v-app-bar>


    <!--
      <hb-header
          one-action
          align-bottom
      >
          <template v-slot:left>
              <hb-page-header
                  title="The Hummingbird-Aviary Design System"
                  description="This guide is for all developers to use for visual styling uniformity. Please use the exact custom components, code, behaviors, styles, and/or classes provided in all instances."
                >
                </hb-page-header>
          </template>
          <template v-slot:right>
            <hb-btn class="pr-1" :class="{'pr-3' : $vuetify.breakpoint.xs}" icon tooltip="Sidebar" @click="toggleSidebar" :active="showNavigation">mdi-table-actions-custom-1</hb-btn> 
          </template>
      </hb-header>
    -->

    <v-navigation-drawer
      app
      flat
      class="mt-60 hb-aviary-navigation"
      disable-resize-watcher
      clipped
      :fixed="$vuetify.breakpoint.smAndUp"
      elevation-0
      mobile-breakpoint="680"
      :value="showNavigation"
      :bottom="$vuetify.breakpoint.smAndDown"
      @input="checkNavigationValue"
    >
      <v-list dense>
          <v-list-item-group v-model="selection" mandatory>
              <v-list-item v-for="item in menuItems" exact :to="'/design-system' + item.path" :key="item.title">
                  {{item.title}}
              </v-list-item>
          </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-container class="ma-0 pa-6" fluid>
      <router-view :menu-items="menuItems"></router-view>
    </v-container>
  </div>
</template>
<script type="text/babel">

    import { EventBus } from '../../EventBus.js';

    export default {
        name: "DesignSystemIndex",
        data: function() {
            return {
                showNavigation: true,
                selection: '',
                search: {},
                menuItems: [
                    { 
                      title: 'Overview',
                      path: '/'
                    },
                    { 
                      title: 'Accordions',
                      path: '/accordions'
                    },
                    { 
                      title: 'Blade Headers',
                      path: '/blade-headers'
                    },
                    { 
                      title: 'Bottom Action Bar',
                      path: '/bottom-action-bar'
                    },
                    { 
                      title: 'Breadcrumbs',
                      path: '/breadcrumbs'
                    },
                    { 
                      title: 'Buttons',
                      path: '/buttons'
                    },
                    { 
                      title: 'Checkboxes',
                      path: '/checkboxes'
                    },
                    { 
                      title: 'Chips',
                      path: '/chips'
                    },
                    { 
                      title: 'Color System',
                      path: '/color-system'
                    },
                    { 
                      title: 'Comboboxes',
                      path: '/comboboxes'
                    },
                    { 
                      title: 'Communications',
                      path: '/communications'
                    },
                    { 
                      title: 'Component Registration',
                      path: '/component-registration'
                    },
                    { 
                      title: 'Component Usage',
                      path: '/component-usage'
                    },
                    { 
                      title: 'Elevations',
                      path: '/elevations'
                    },
                    { 
                      title: 'Empty States',
                      path: '/empty-states'
                    },
                    { 
                      title: 'File Inputs',
                      path: '/file-inputs'
                    },
                    { 
                      title: 'Forms & Validation',
                      path: '/forms'
                    },
                    { 
                      title: 'Global CSS',
                      path: '/global-css'
                    },
                    { 
                      title: 'Global Notifications',
                      path: '/global-notifications'
                    },
                    { 
                      title: 'Header Info (Deprecated)',
                      path: '/header-info'
                    },
                    { 
                      title: 'Headers',
                      path: '/headers'
                    },
                    { 
                      title: 'Icons',
                      path: '/icons'
                    },
                    { 
                      title: 'Info Cards',
                      path: '/info-cards'
                    },
                    {
                      title: 'Inputs',
                      path: '/inputs'
                    },
                    { 
                      title: 'Links',
                      path: '/links'
                    },
                    /*
                    { 
                      title: 'Media Viewers',
                      path: '/media-viewers'
                    },
                    */
                    { 
                      title: 'Menus',
                      path: '/menus'
                    },
                    { 
                      title: 'Mini Profiles',
                      path: '/mini-profiles'
                    },
                    { 
                      title: 'Modals',
                      path: '/modals'
                    },
                    { 
                      title: 'Notifications',
                      path: '/notifications'
                    },
                    { 
                      title: 'Page Menus',
                      path: '/page-menus'
                    },
                    { 
                      title: 'Property Selector',
                      path: '/property-selector'
                    },
                    { 
                      title: 'Radios',
                      path: '/radios'
                    },
                    { 
                      title: 'Spacing',
                      path: '/spacing'
                    },
                    { 
                      title: 'Space Icons',
                      path: '/space-icons'
                    },
                    { 
                      title: 'Statuses',
                      path: '/statuses'
                    },
                    { 
                      title: 'Statuses General',
                      path: '/statuses-general'
                    },
                    { 
                      title: 'Steppers',
                      path: '/steppers'
                    },
                    { 
                      title: 'Switches',
                      path: '/switches'
                    },
                    { 
                      title: 'Tables',
                      path: '/tables'
                    },
                    { 
                      title: 'Tabs',
                      path: '/tabs'
                    },
                    { 
                      title: 'Tasks',
                      path: '/tasks'
                    },
                    { 
                      title: 'Timelines',
                      path: '/timelines'
                    },
                    { 
                      title: 'Toasts',
                      path: '/toasts'
                    },
                    { 
                      title: 'Tooltips',
                      path: '/tooltips'
                    },
                    { 
                      title: 'Typography',
                      path: '/typography'
                    },
                    { 
                      title: 'Unit Icons (Deprecated)',
                      path: '/unit-icons'
                    },
                ]
            };
        },
        methods: {
          toggleSidebar() {
            this.showNavigation = !this.showNavigation;
            EventBus.$emit("design-system-sidebar-toggled");
          },
          checkNavigationValue(event){
            this.showNavigation = event;
          },
          goToPage(page){
            const nav = document.getElementsByClassName('v-navigation-drawer__content')[0];
            var menuIndex = this.menuItems.map(function(s) { return s.title; }).indexOf(page.title);
            var menuItem = document.getElementsByTagName('a')[menuIndex];
            menuItem.click();
            nav.scrollTo({
              top: menuItem.offsetTop,
              behavior: 'smooth'
            });
          }
        },
        watch: {
            '$route': function(){
                window.scrollTo(0,0);
                this.search = {};
            }
        }
    }
</script>

<style scoped>

.hb-aviary-main-page {
  background-color: #F9FAFB;
}

.hb-aviary-main-page :deep(.hb-design-system-border-radius) {
  border-radius: 4px;
}

.hb-aviary-navigation {
  background-color: #fff;
}

.hb-aviary-navigation .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #637381 !important;
}

.hb-aviary-navigation .v-list-item {
  border-left: 4px solid #fff;
  padding-left: 20px;
}

.hb-aviary-navigation .v-item--active {
  color: #101318;
  font-weight: 500;
  background-color: #e0f5f5;
  border-left: 4px solid #00848E;
}

.hb-aviary-navigation .v-list-item::before {
  background-color: transparent;
}

.hb-aviary-navigation .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
  color: #101318 !important;
}

.hb-aviary-ti-logo {
  width: 120px;
}

.hb-aviary-main-page:deep(.theme--light.v-tabs-items) {
    background-color:#f9fafb !important;
}

</style>